import { useEffect, useState } from "react";
import {
  faBars,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { motion } from "framer-motion";
import { RoomEvent } from "livekit-client";
import { Join } from "./Join/Join";
import { Activity } from "./Activity/Activity";
import { MenuButton } from "./MenuButton";
import { Dashboard } from "./Dashboard/Dashboard";
import { TTSParticipant } from "./TTSParticipant";
import { LiveKitProgrammableVideoProvider } from "hooks/livekit/useLiveKitProgramableVideo/useLiveKitProgramableVideo";
import useConnectionOptions from "hooks/livekit/useConnectionOptions/useConnectionOptions";
import useRoomOptions from "hooks/livekit/useRoomOptions/useRoomOptions";
import { getRef, SessionProvider, useSessionContext } from "hooks/useSession";
import useRoomState from "hooks/livekit/useRoomState/useRoomState";
import { GestureStateProvider } from "hooks/useGestureState/useGestureState";
import { useAdminPermission } from "hooks/useAdminPermission/useAdminPermission";
import DeviceSettingsModal from "./DeviceSettingsModal/DeviceSettingsModal";
import { OkoSessionProvider } from "hooks/useOkoSession/useOkoSession";
import { useAppStateContext } from "hooks/useAppState/useAppState";
import { request } from "hooks/useOkoApi/useOkoApi";
import { useFirebase } from "hooks/useFirebase";
import { useAuthUserDataContext } from "hooks/useAuthUserData/useAuthUserData";
import { useUser } from "hooks/useUser/useUser";
import useVideoContext from "hooks/livekit/useVideoContext/useVideoContext";
import { constants } from "utils";

export const Session = () => {
  const connectionOptions = useConnectionOptions();
  const roomOptions = useRoomOptions();
  const { sessionAuthType } = useAppStateContext();

  const onError = (error) => {
    console.log("LiveKitProgrammableVideoProvider Error", error);
  };

  return (
    <SessionProvider>
      {sessionAuthType && <LiveKitProgrammableVideoProvider
        connectionOptions={connectionOptions}
        roomOptions={roomOptions}
        onError={onError}
      >
        <OkoSessionProvider>
          <DeviceSettingsModal />
          <GestureStateProvider>
            <Room />
          </GestureStateProvider>
        </OkoSessionProvider>
      </LiveKitProgrammableVideoProvider>}
    </SessionProvider>
  );
};

const Room = () => {
  const roomState = useRoomState();
  const { hasAdminPermission } = useAdminPermission();
  const [isLoading, setIsLoading] = useState(false);
  const { displayName, groupCode, sessionAuthType } = useAppStateContext();
  const firebase = useFirebase();
  const { isStart, setSessionId } = useSessionContext();
  const { authUserData } = useAuthUserDataContext();
  const { update: updateUser } = useUser(authUserData?.id);
  const { connect: connectVideo } = useVideoContext();

  const startSession = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      // The second bool - currentUser.isAnonymous - makes sure to not update the displayName in case of testing
      // and going back and forth from "google" to "anonymous" auth options in BE auth_supported API.
      // The same scenario should never happen outside of testing, as the user would not go from "google" to
      // "anonymous" auth, but even if so, the name will be asked for every time as per the design and will be
      // updated properly.
      if (sessionAuthType === constants.ANONYMOUS && currentUser.isAnonymous) {
        currentUser.updateProfile({ displayName: displayName })
          .catch((e) => {
            setIsLoading(false);
            Sentry.captureException(e);
            console.log(e);
          });
      }
      const groupResponse = await request(`group/${groupCode}/join`, { method: "GET" });
      setSessionId(groupResponse.session_id);
      await connectVideo(groupResponse.livekit_url, groupResponse.livekit_auth_token);
      const ref = getRef(groupResponse.session_id);
      const sessionResponse = await request(`session/?session_id=${groupResponse.session_id}`, { method: "GET" });
      const sessionData = (await ref.get()).data();
      if (!sessionData) {
        await ref.set(
          {
            id: groupResponse.session_id,
            sessionId: groupResponse.session_id,
            okoSessionId: groupResponse.session_id,
            creatorId: "server",
            auth: groupResponse,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            autoStart: true,
            activityId: sessionResponse.activity_type ? "ready-room" : "icon-select",
            sessionActivityType: sessionResponse.activity_type ? sessionResponse.activity_type : null,
          },
          { merge: true }
        );
      }
      const role = constants.STUDENT;
      const displayNameToSet = displayName || authUserData.displayName;
      updateUser({ displayNameToSet, role });

    } catch (e) {
      setIsLoading(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  useEffect(async () => {
    if (!isLoading && isStart) {
      setIsLoading(true);
      await startSession();
    }
  },[isLoading, isStart])

  if (roomState === RoomEvent.Disconnected) return <Join />;

  return (
    <>
      <TTSParticipant />
      {hasAdminPermission ? <AdminUi /> : <Activity />}
    </>
  );
};

const AdminUi = () => {
  const [showDashboard, setShowDashboard] = useState(false);

  return (
    <>
      <MenuButton
        style={{ position: "absolute", left: 16, top: 16 }}
        icon={showDashboard ? faExternalLinkSquareAlt : faBars}
        onClick={() => {
          setShowDashboard(!showDashboard);
        }}
      />
      {showDashboard && <Dashboard />}
      <motion.div
        style={{
          transformOrigin: "top left",
          cursor: showDashboard ? "pointer" : "auto",
          boxShadow: "transparent 1px 1px 1px 1px",
        }}
        animate={{
          scale: showDashboard ? (260 - 20) / window.innerWidth : 1,
          x: showDashboard ? 10 : 0,
          y: showDashboard ? 10 : 0,
        }}
        transition={{ type: "linear" }}
        onClick={() => {
          showDashboard && setShowDashboard(!showDashboard);
        }}
      >
        <Activity disable={showDashboard} />
      </motion.div>
    </>
  );
};
